import loadable from '@loadable/component'
import * as React from 'react'
import { Provider } from 'react-redux'
import store from './src/redux/store'

const FirebaseProvider = loadable(() =>
  import('./src/services/firebase/firebase-provider')
)

export const wrapRootElement = ({ element }) => {
  return (
    <Provider store={store}>
      <FirebaseProvider>{element}</FirebaseProvider>
    </Provider>
  )
}
