import { createSlice } from '@reduxjs/toolkit'

export const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    modalSlice: false,
  },
  reducers: {
    handleShow: (state, action) => {
      state.modalSlice = action.payload
    },
  },
})

export const { handleShow } = modalSlice.actions
export const modalState = state => state.modal.modalSlice
export default modalSlice.reducer
