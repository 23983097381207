import { createSlice } from '@reduxjs/toolkit'

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    list: [],
    registered: false,
  },
  reducers: {
    getUsers: (state, action) => {
      state.list = action.payload
    },
    changeRegistered: (state, action) => {
      state.registered = action.payload
    },
  },
})

export const { getUsers, changeRegistered } = usersSlice.actions
export const usersState = state => state.users
export default usersSlice.reducer
