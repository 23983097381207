import { Action, combineReducers, configureStore } from '@reduxjs/toolkit';
import { load, save } from 'redux-localstorage-simple';
import { ThunkAction } from 'redux-thunk';
import modalReducer from './slice/modalSlice';
import usersReducer from './slice/users';


const rootReducer = combineReducers({
  modal: modalReducer,
  users: usersReducer,
})

const store = configureStore({
  reducer: rootReducer,
  preloadedState: load(),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(save()),
})

export type RootState = ReturnType<typeof rootReducer>
export type Dispatch = typeof store.dispatch
export type Thunk = ThunkAction<void, RootState, null, Action<string>>

export default store
